<template>
  <div class="wrapper">
    <b-spinner variant="primary" style="width: 30px; height: 30px" />
    <span class="title">Saindo...</span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import router from '@/router';

const { mapActions } = createNamespacedHelpers('auth');

export default {
  methods: {
    ...mapActions(['logOut'])
  },
  created() {
    this.logOut().then(() => {
      router.replace({
        name: 'login'
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  padding-left: 20px;
  font-size: 20px;
}
</style>
